export default [
	// 客户退款详情
	{
		path: 'refundDetail',
		name: 'refundDetail',
		component: () => import('@/views/financeManage/refundManage/detail.vue'),
	},
	// 索赔减免详情
	{
		path: 'claimReductionManageDetail',
		name: 'claimReductionManageDetail',
		component: () => import('@/views/financeManage/claimReductionManage/childPage/detail.vue'),
	},
	// 维修减免详情  -hh
	{
		path: 'maintenanceDetails',
		name: 'maintenanceDetails',
		component: () => import('@/views/financeManage/maintenanceRelief/maintenanceDetails.vue'),
	},
	// 维修减免编辑  -hh
	{
		path: 'maintenanceEdit',
		name: 'maintenanceEdit',
		component: () => import('@/views/financeManage/maintenanceRelief/maintenanceEdit.vue'),
	},

	// 应收详情审批
	{
		path: 'receivableDetails',
		name: 'receivableDetails',
		component: () => import('@/views/financeManage/receivableManage/receivableDetails.vue'),
	},
	// 应收重新发起，编辑，新增
	{
		path: 'receivableEdit',
		name: 'receivableEdit',
		component: () => import('@/views/financeManage/receivableManage/receivableEdit.vue'),
	},
	// 单车结算单
	{
		path: 'bicycleMange', // 单车结算单管理
		name: 'bicycleMange',
		component: () => import('@/views/financeManage/statementManage/bicycle/bicycle.vue'),
	},
	//汇总结算单详情
	{
		path: 'summaryDetails',
		name: 'summaryDetails',
		component: () => import('@/views/financeManage/statementManage/summary/summaryDetails.vue'),
	},
	// 对账单详情
	{
		path: 'statementListDetails',
		name: 'statementListDetails',
		component: () => import('@/views/financeManage/statement/statementListDetails.vue'),
	},
	// 履行结算单详情
	{
		path: 'performDetails',
		name: 'performDetails',
		component: () =>
			import('@/views/financeManage/statementManage/performSettlement/performDetails.vue'),
	},
	// 收款详情
	{
		path: 'collectionDetil',
		name: 'collectionDetil',
		component: () => import('@/views/financeManage/proceedManage/collectionDetil.vue'),
	},
	// 诉讼详情
	{
		path: 'litigationDetail',
		name: 'litigationDetail',
		component: () =>
			import('@/views/financeManage/litigationManage/components/litigationDetail.vue'),
	},
	// 诉讼审批
	{
		path: 'litigationExamine',
		name: 'litigationExamine',
		component: () =>
			import('@/views/financeManage/litigationManage/components/litigationExamine.vue'),
	},
	//催收详情
	{
		path: 'urgentRecallDetail',
		name: 'urgentRecallDetail',
		component: () =>
			import(
				'@/views/financeManage/urgentRecallManage/urgentRecallManage/urgentRecallDetail.vue'
			),
	},
	//延期付款详情
	{
		path: 'delayPayDetail',
		name: 'delayPayDetail',
		component: () =>
			import('@/views/financeManage/urgentRecallManage/delayPayManage/childPage/detail.vue'),
	},
	//延期审批
	{
		path: 'delayPayApproval',
		name: 'delayPayApproval',
		component: () =>
			import(
				'@/views/financeManage/urgentRecallManage/delayPayManage/childPage/approval.vue'
			),
	},
	//本月预计收入统计表(财务)
	{
		path: 'financeForms',
		name: 'financeForms',
		component: () => import('@/views/financeManage/reportForms/financeForms/index.vue'),
	}, //本月预计收入统计表(业务)
	{
		path: 'vocationalForms',
		name: 'vocationalForms',
		component: () => import('@/views/financeManage/reportForms/vocationalForms/index.vue'),
	},
	// 案件详情
	{
		path: 'caseListDetail',
		name: 'caseListDetail',
		component: () => import('@/views/financeManage/urgentRecallManage/caseList/detail.vue'),
	},
	// 当月每日结算单统计
	{
		path: 'invoicesDailyStatisticsForms',
		name: 'invoicesDailyStatisticsForms',
		component: () =>
			import('@/views/financeManage/reportForms/invoicesDailyStatisticsForms/index.vue'),
	},
]
